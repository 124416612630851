import { ReactComponent as ErrorIllustration } from "assets/images/errorBlocked.svg";

function BlockedUserPage() {
  return (
    <div className="flex flex-col justify-center items-center h-full max-h-screen w-full p-5">
      <ErrorIllustration className="max-w-[60%]" />
    </div>
  );
}

export default BlockedUserPage;
