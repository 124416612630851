import {
  faAddressCard,
  faCommentDots,
  faMailBulk,
  faMoneyBill,
  faPencil,
  faPeopleGroup,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createRoom } from "app/services/socket/chat";
import { selectAuth } from "app/store/slices/auth-slice";
import { useSelector } from "react-redux";
import { getLocalizedNumber, getLocalizedWord } from "../../../app/utils/lang";
import { useTranslation } from "react-i18next";
import Modal from "components/Modal/Modal";
import { useState } from "react";
import _ from "lodash";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "app/constants";

const AgentCard = ({ agent }) => {
  const { t } = useTranslation();
  const user = useSelector(selectAuth);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  function startChatHandler() {
    createRoom({ [user.profile.role]: user._id, child: agent._id });
  }

  function agentClickHandler(e) {
    e.stopPropagation();

    if (!_.isObject(agent?.children?.[0])) return; //this checks that the agent has children with data
    setShowModal(true);
  }

  function editAgentHandler(e) {
    e.stopPropagation();
    navigate(`${ROUTES.SUBAGENTS.EDIT}/${agent._id}`);
  }

  return (
    <div
      className={classNames(
        "overflow-hidden relative",
        "p-2",
        "agent-card flex flex-col",
        "hover:shadow-lg  active:scale-[0.99] transition-transform duration-100 ease-in-out",
        {
          "cursor-pointer hover:scale-105": _.isObject(agent?.children?.[0]),
          "cursor-default hover:scale-[1.01]": !_.isObject(
            agent?.children?.[0]
          ),
        }
      )}
      onClick={agentClickHandler}
    >
      {/* <div className="absolute end-5 z-50 top-20 flex flex-col gap-3 ">
        <button
          className="text-amber-800 w-8 h-8 hover:text-amber-600 aspect-square active:scale-90 transition-all duration-75 rounded-full p-1 bg-amber-100 hover:bg-amber-200 active:bg-amber-300"
          onClick={editAgentHandler}
        >
          <FontAwesomeIcon icon={faPencil} />
        </button>
      </div> */}
      <div className="flex flex-row gap-3 items-start">
        <div className="name-icon mt-1">
          <FontAwesomeIcon icon={faUser} />
        </div>
        <div className="flex flex-col">
          <p className="text-xs text-slate-700 font-semibold">
            {t("Level")} {getLocalizedNumber(agent.level)}
          </p>
          <div className="name-text font-semibold text-xl leading-4">
            {getLocalizedWord(agent.name) ?? "agent name"}
          </div>
        </div>
        <div className="chat-icon ltr:ml-auto rtl:mr-auto">
          <FontAwesomeIcon
            className="drop-shadow hover:drop-shadow-lg icon hover:scale-105 active:scale-95"
            icon={faCommentDots}
            size="lg"
            onClick={startChatHandler}
          />
        </div>
      </div>

      <div className="flex flex-row gap-3">
        <div className="credits-icon">
          <FontAwesomeIcon icon={faMoneyBill} />
        </div>
        <div className="credits-text capitalize">
          {t("credit", { count: agent.credit ?? 0 })}:{" "}
          <b className="px-2">{getLocalizedNumber(agent.credit ?? 0)}</b>
        </div>
      </div>
      <div className="flex flex-row gap-3">
        <div className="credits-icon">
          <FontAwesomeIcon icon={faPeopleGroup} />
        </div>
        <div className="credits-text capitalize">
          {t("subAgent", { count: agent.children?.length ?? 0 })}:{" "}
          <b className="px-2">
            {getLocalizedNumber(agent.children?.length ?? 0)}
          </b>
        </div>
      </div>
      <div className="flex flex-row gap-3">
        <div className="details-icon">
          <FontAwesomeIcon icon={faPhone} />
        </div>
        <div className="details-text">{agent.phone ?? "no phone provided"}</div>
      </div>
      <div className="flex flex-row gap-3">
        <div className="details-icon">
          <FontAwesomeIcon icon={faMailBulk} />
        </div>
        <div className="details-text">{agent.email}</div>
      </div>
      <div className="flex flex-row gap-3">
        <div className="details-icon">
          <FontAwesomeIcon icon={faAddressCard} />
        </div>
        <div className="details-text">
          {agent.address ?? (agent.city && agent.country)
            ? `${getLocalizedWord(agent.city)}, ${getLocalizedWord(
                agent.country
              )}`
            : "no address provided"}
        </div>
      </div>
      <Modal
        visible={showModal}
        onClose={() => setShowModal(false)}
        className="sub-agents-page p-2 md:p-4 overflow-y-auto"
        title="children"
      >
        <div className="flex gap-3 flex-wrap justify-center items-center">
          {agent.children?.map((child) => (
            <AgentCard key={child._id} agent={child} />
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default AgentCard;
