import { ROUTES } from "app/constants";
import { ReactComponent as SponsorImg } from "assets/images/sponsor-img.svg";
import { CardContainer, MainButton } from "components";
import { useNavigate } from "react-router-dom";

import "./Ads.scss";
import { useTranslation } from "react-i18next";

function SponsorAds() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <CardContainer className="sponsor-page" title={"Sponsor Ads"}>
      <div className="page-container">
        <SponsorImg />
        <h2>{t("manageYourAds")}</h2>
        <p>{t("editOrCreateAd")}</p>

        <MainButton onClick={() => navigate(ROUTES.ADS.CREATE)}>
        {t("createAd")}
        </MainButton>
        <MainButton
          onClick={() => navigate(ROUTES.ADS.LIST)}
          className="capitalize"
        >
          {t("previous ads")}
        </MainButton>
      </div>
    </CardContainer>
  );
}

export default SponsorAds;
