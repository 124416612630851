import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STORE } from "../../constants";
import agentServices from "../../services";

const { AUTH } = STORE;

export const loginThunk = createAsyncThunk(
  "auth/login",
  async (obj, thunkAPI) => {
    try {
      const response = await agentServices.login(obj);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ ...error.response.data }); // return the returned error response
    }
  }
);

export const getUserProfile = function () {
  try {
    const authProfile = localStorage.getItem(AUTH.PROFILE);
    try {
      return JSON.parse(authProfile);
    } catch (jsonError) {
      // Handle non-JSON strings here
      return authProfile;
    }
  } catch (e) {
    localStorage.removeItem(AUTH.PROFILE);
    return null;
  }
};

export const getLocalStorageKey = function (key) {
  try {
    const storeKey = localStorage.getItem(key);

    try {
      return JSON.parse(storeKey);
    } catch (jsonError) {
      // Handle non-JSON strings here
      return storeKey;
    }
  } catch (e) {
    // localStorage.removeItem(key);
    return null;
  }
};

export const setLocalStorageKey = function (key, obj) {
  localStorage.setItem(key, JSON.stringify(obj));
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: getLocalStorageKey(AUTH.TOKEN),
    role: getLocalStorageKey(AUTH.ROLE),
    _id: getLocalStorageKey(AUTH.ID),
    profile: getUserProfile(), //this will raise an error
  },

  reducers: {
    setRole(state, { payload }) {
      state.role = payload;
    },

    update(state, { payload: { profile } }) {
      const userObj = {
        ...profile,
        name_en: profile?.name?.en,
        name_ar: profile?.name?.ar,
      };

      state.profile = userObj;

      setLocalStorageKey(AUTH.PROFILE, userObj);
      return state;
    },

    updateImage(state, { payload }) {
      state.profile.image = payload;
      setLocalStorageKey(AUTH.PROFILE, state.profile);
    },

    // update(state, { payload: { userData } }) {
    //   const userDataObj = JSON.stringify(userData);
    //   localStorage.setItem("userData", userDataObj);
    //   state.userData = getUserData();
    //   return state;
    // },

    logout(state) {
      localStorage.removeItem(AUTH.TOKEN);
      localStorage.removeItem(AUTH.ID);
      localStorage.removeItem(AUTH.ROLE);
      localStorage.removeItem(AUTH.PROFILE);

      state._id = "";
      state.token = "";
      state.profile = {};
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loginThunk.fulfilled, (state, { payload }) => {
      setLocalStorageKey(AUTH.TOKEN, payload.token);
      setLocalStorageKey(AUTH.ID, payload.record._id);

      setLocalStorageKey(AUTH.PROFILE, payload.record);

      state._id = payload.record._id;
      state.token = payload.token;
      state.profile = payload.record;
    });
  },
});

export const {
  logout,
  setRole,
  update,
  updateImage,
  update: setUpdateProfile,
} = authSlice.actions;
export const authActions = authSlice.actions;
export const selectAuth = (state) => state.auth;

export default authSlice.reducer;
