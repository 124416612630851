import { ROUTES } from "app/constants";
import { clientsServices } from "app/services/modules/clients";
import toastPopup, { responseErrorToast } from "app/utils/toastPopup";
import { MainButton } from "components";
import { MainInput } from "components/Inputs";
import Modal from "components/Modal/Modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function ScanCodeModal({ showCodeModal, closeCodeModal }) {
  const { t } = useTranslation();
  const [code, setCode] = useState({ barcode: "" });
  const navigate = useNavigate();

  const handleClientCode = async (e) => {
    e.preventDefault();
    const barcode = code.barcode;
    if (!barcode) return toastPopup.error(t("enterCode") + "!");

    clientsServices
      .getClient({ barcode })
      .then((res) => res.record)
      .then((res: IClientItem[]) => {
        closeCodeModal();
        setTimeout(() => {
          const clientId = res[0]._id;
          navigate(ROUTES.CLIENTS.LIST + `/${clientId}`);
        }, 50);
      })
      .catch(responseErrorToast);
  };
  return (
    <Modal
      visible={showCodeModal}
      onClose={closeCodeModal}
      title="Barcode"
      className="py-4 px-2 min-w-[15rem]"
    >
      <form onSubmit={handleClientCode} className="flex flex-col w-full gap-4">
        <MainInput
          type="text"
          state={code}
          setState={setCode}
          name="barcode"
          maxLength="12"
        />
        <MainButton className="!text-sm !py-2 !h-auto capitalize" type="submit">
          {t("confirm")}
        </MainButton>
      </form>
    </Modal>
  );
}
export default ScanCodeModal;

export type IName = {
  en: null | string;
  ar: null | string;
};
export type IOrg = {
  _id: string;
  name: IName;
};
export type ILocation = {
  lat: null;
  long: null;
};
export type ICountry = {
  en: null;
  ar: null;
};
export type ICity = {
  en: null;
  ar: null;
};

export type IClientItem = {
  _id: string;
  name: IName;
  barcode: string;
  org?: IOrg;
  email: null;
  phone: null;
  image: null;
  age: null;
  gender: null;
  isActive: boolean;
  location: ILocation;
  country: ICountry;
  city: ICity;
  joinDate: string;
  role: string;
  type: string;
  dateOfBirth: null;
  interests: any[];
  profession: any[];
  usedFreeTrial: boolean;
  isSubscribed: boolean;
  credit: number;
  isPending: boolean;
};
