import agentServices from "app/services";
import {
  connectSocket,
  disconnectSocket,
  EVENTS,
  socket,
} from "app/services/socket/config";
import {
  listenToNotification,
  listNotification,
} from "app/services/socket/notification";
import { setNotifications } from "app/store/actions";
import { selectAuth } from "app/store/slices/auth-slice";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate, useNavigation } from "react-router-dom";
import useSWR from "swr";
import { Footer, Navbar } from "./components";
import i18n from "app/locales/i18n";
import { checkFixLang } from "app/utils/lang";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { SocketProvider } from "app/services/socket/provider";

const { CONNECTION } = EVENTS;

function App() {
  const navigate = useNavigate();
  const auth = useSelector(selectAuth);
  const navigation = useNavigation();
  const lang = i18n.language;

  useSWR("my-profile", () => agentServices.updateAgent(auth._id, {}));

  useEffect(() => {
    connectSocket();
    listenToNotification((res) => console.log(res));
    listNotification(
      {
        "city.en": "Alexandria",
        // gender: "male",
      },
      (response) => setNotifications(response)
    );

    socket.on(EVENTS.CHAT.CREATE, (res) => {
      if (!res.success) {
        return;
      }

      const room = res.record;
      console.log(room, "ROOOM");
      navigate("/chat", { state: { room } });
    });

    return () => {
      socket.off(CONNECTION.OPEN);
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    checkFixLang(lang);
  }, [lang]);

  return (
    <>
      {navigation.state === "loading" ? (
        <div className="fixed top-0 inset-x-0 bg-green-600 z-50 !duration-75 h-2 animate-pulse transition-all"></div>
      ) : null}

      <div className="base-layout">
        <Navbar />

        <div className="base-nav-item"></div>

        <div className="page-content !p-0">
          <SocketProvider>
            <Outlet />
          </SocketProvider>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default App;
