import { authServices } from "app/services/modules/auth";
import { setRole } from "app/store/slices/auth-slice";
import toastPopup from "app/utils/toastPopup";
import { MainButton } from "components";
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";
import { MainInput } from "components/Inputs";
import jwtDecode from "jwt-decode";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

function SendRecoveryMailView({ input, setInput }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const roleName = input?.role?._id;

  async function handleRecoverySend(e) {
    e.preventDefault();

    setErrorList([]);

    const _err = [];
    if (!roleName) _err.push("Please select user type");
    if (!input.email) _err.push("enterYourEmail");
    if (_err.length) return setErrorList(_err);

    dispatch(setRole(roleName));

    setLoading(true);
    try {
      const res = await authServices.getRecoveryCode({ email: input.email });
      toastPopup.success("Please check your email for the code!");

      setInput((obj) => ({
        ...obj,
        step: 1,
        recievedCode: jwtDecode(res.token)?.code | "",
        token: res.token,
      }));
    } catch (e) {
      //
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  }

  return (
    <form
      onSubmit={handleRecoverySend}
      className="flex flex-col w-full mx-auto"
    >
      <h3 className="capitalize text-center my-3">{t("forgotPassword")}</h3>

      <div className="px-7 w-full space-y-4 max-w-[30rem] mx-auto">
        <MainInput
          name="role"
          type="multi-select"
          className="w-full"
          isMulti={false}
          state={input}
          identifier="name"
          list={[
            { _id: "agent", name: { en: "Agent", ar: "عميل" } },
            { _id: "org", name: { en: "Organization", ar: "مؤسسة" } },
          ]}
          setState={setInput}
          closeMenuOnSelect
        />
        <MainInput
          state={input}
          setState={setInput}
          name="email"
          className="w-full"
        />

        <FormErrorMessage errorList={errorList} />
        <MainButton
          text={"send"}
          loading={loading}
          type="submit"
          className="px-7 w-full max-w-[30rem] mx-auto py-2 mt-8 text-xl"
        />
      </div>
    </form>
  );
}

export default SendRecoveryMailView;
