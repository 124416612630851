import { ROUTES } from "app/constants";
import { logout } from "app/store/actions";
import { selectAuth } from "app/store/slices/auth-slice";
import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const ProtectedComponent = ({ children, role }) => {
  const auth = useSelector(selectAuth);

  const userRole = auth.profile?.role;

  const isAuthPermitted = role === "auth";
  const isRolePermitted = userRole === role;

  // but is not generally permitted and his role is not permitted to access it
  if (!isRolePermitted && !isAuthPermitted) return null;

  // logged in and has an active profile with the permitted role
  return children;
};
