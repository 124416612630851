import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import "./RoutingTab.scss";

const RoutingTab = ({ routes, className }) => {
  const params = useParams();
  const { t } = useTranslation();

  return (
    <div className={classNames(className, "routing-tabs-container")}>
      <div className="tab-route capitalize">
        <NavLink
          to={routes[0].route}
          className={(navData) =>
            navData.isActive ? "active tab-link" : "tab-link"
          }
        >
          {t(routes[0].name)}
        </NavLink>
      </div>
      <div className="tab-route">
        <NavLink
          to={routes[1].route}
          className={(navData) =>
            navData.isActive ? "active tab-link" : "tab-link"
          }
        >
          {t(routes[1].name)}
        </NavLink>
      </div>
    </div>
  );
};

export default RoutingTab;

RoutingTab.prototype = {
  routes: PropTypes.arrayOf(
    PropTypes.objectOf({ name: PropTypes.string, route: PropTypes.string })
  ).isRequired,
  className: PropTypes.string,
};
