import Search from "components/Inputs/Search/Search";
import Pagination from "components/Pagination/Pagination";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const SearchContext = createContext();
// Create a custom hook to easily access the SearchContext values
function useSearch() {
  return useContext(SearchContext);
}

// Create a component to provide the SearchContext to its children
export function SearchProvider({
  queryParams,
  setQueryParams,
  initialFilters,
  filter,
  setFilter,
  withSideFilter = true,
  listRenderFn,
  itemsCount,
  children,
  limit: LIMIT = 100,
}) {
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const totalPages = Math.ceil(itemsCount / LIMIT);

  const handleListSearch = () => {
    if (!searchQuery) return;
    const arabicReg = /[\u0621-\u064A]/g;
    const isArabic = arabicReg.test(searchQuery);
    const queryObj = {
      ...(!isArabic && { "name.en": searchQuery }),
      ...(isArabic && { "name.ar": searchQuery }),
    };
    setQueryParams((prev) => ({ ...prev, page: 1, ...queryObj }));
  };

  useEffect(() => {
    if (!searchQuery) {
      setQueryParams((prev) => ({
        ...prev,
        page: 1,
        limit: LIMIT,
        "name.en": undefined,
        "name.ar": undefined,
      }));
    } else {
      handleListSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    setQueryParams((p) => ({ ...p, ...filter }));
  }, [filter]);

  useEffect(() => {
    setSearchQuery("");
    setFilter && setFilter(initialFilters);
    setQueryParams({ page: 1, limit: LIMIT, ...initialFilters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  // Pass the search query and update function as values to the SearchContext
  return (
    <SearchContext.Provider
      value={{
        searchQuery,
        setSearchQuery,
        handleListSearch,
        listRenderFn,
        totalPages,
        queryParams,
        setQueryParams,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

// Use the useSearch hook in your SearchBar component to access the search query and update function
export function SearchBar() {
  const { searchQuery, setSearchQuery, handleListSearch } = useSearch();

  return (
    <Search
      setSearchQuery={setSearchQuery}
      searchQuery={searchQuery}
      onClick={handleListSearch}
    />
  );
}

// Use the useSearch hook in your SearchResults component to access the search query
export function PageQueryWrapper({ children }) {
  const { listRenderFn, totalPages, queryParams, setQueryParams } = useSearch();

  // Render your search results based on the search query
  return (
    <section className="flex flex-col p-2 md:p-8 min-h-[80vh]">
      {children}
      <div className="flex flex-row h-full max-h-screen gap-2">
        <main className="flex flex-row w-full h-full gap-8 flex-wrap max-h-[85vh] overflow-y-auto p-5 justify-around flex-grow shadow shadow-primary/25 rounded-lg">
          {listRenderFn()}
        </main>
      </div>
      <Pagination
        count={totalPages}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />
    </section>
  );
}
