import store from "app/store";
import { API } from "app/constants";
import api from "../api";

/*--- AGENTS ---*/
const { REQUESTS } = API;

export const requestsServices = {
  listAllIncomingRequests: async () =>
    (
      await api.get(REQUESTS.LIST, {
        params: { to: store.getState().auth._id, status: "pending" },
      })
    ).data,
  listAllOutgoingRequests: async () =>
    (
      await api.get(REQUESTS.LIST, {
        params: { from: store.getState().auth._id },
      })
    ).data,

  createRequest: async (obj) => await api.post(REQUESTS.CREATE, obj),

  acceptRequest: async (endpoint, _id) =>
    (await api.put(endpoint, { isActive: true }, { params: { _id } }))?.data,

  rejectRequest: async (_id) =>
    (
      await api.put(
        REQUESTS.UPDATE,
        { status: "rejected" },
        { params: { _id } }
      )
    )?.data,
};
