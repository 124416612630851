import { faImage, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { agentsServices } from "app/services/modules/agents";
import { authActions, selectAuth } from "app/store/slices/auth-slice";
import toastPopup, { responseErrorToast } from "app/utils/toastPopup";
import { MainButton } from "components";
import { MainInput } from "components/Inputs";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import MainImage from "components/MainImage/MainImage";
import { clearEmpty } from "helpers/clear-empty";
import useCountriesArr from "helpers/useCountriesArr";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useSWR from "swr";
import EditPassworModal from "./_components/EditPasswordModal";

const genderList = [
  { _id: "male", gender: { en: "male", ar: "ذكر" } },
  { _id: "female", gender: { en: "female", ar: "أنثى" } },
];

export function AccountDetails() {
  const user = useSelector(selectAuth);
  const { cities, countries, setCities } = useCountriesArr();
  const ref = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data: accountData } = useSWR("account-details", () =>
    agentsServices.updateAgent(user._id)
  );

  const { record: account = undefined } = accountData ?? {};

  const auth = useSelector((state) => state.auth);

  const userData = account ?? auth.profile;
  const initialUserInfo = {
    name_en: userData?.name.en,
    name_ar: userData?.name.ar,
    email: userData?.email,
    phone: userData?.phone,
    image: userData?.image,
    age: userData?.age,
    gender: userData?.gender,
    country: {
      ...userData?.country,
      name: { en: userData?.country?.en, ar: userData?.country?.ar },
      _id: userData?.country?.index,
    },
    city: {
      ...userData?.city,
      name: { en: userData?.city?.en, ar: userData?.city?.ar },
      _id: userData?.city?.index,
    },
  };

  const [loading, setLoading] = useState(false);
  const [imgUpdated, setImgUpdated] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [updateImage, setUpdateImage] = useState(userData?.image?.Location);
  const [uploadImage, setUploadImage] = useState(updateImage);

  const [userInfo, setUserInfo] = useState(initialUserInfo);

  const formData = [
    { name: "name_en", type: "text", required: true },
    { name: "name_ar", type: "text", required: true },
    { name: "email", type: "email", required: true, toEdit: true },
    { name: "phone", type: "tel", toEdit: true },
    {
      name: "country",
      type: "multi-select",
      isMulti: false,
      list: countries,
      identifier: "name",
      required: true,
    },
    {
      name: "city",
      type: "multi-select",
      isMulti: false,
      list: cities,
      identifier: "name",
      required: true,
    },
    ...(userData.role === "agent"
      ? [
          {
            name: "gender",
            type: "list",
            list: genderList,
            identifier: "gender",
            required: true,
          },
        ]
      : []),
  ];

  const imageUploader = (e) => {
    ref.current.click();
  };

  function handleClosePasswordModal() {
    setShowPasswordModal(false);
  }

  const updateDetails = (e) => {
    /**
     * - toggle input edit with a pen icon button
     * - save button => collects the updates and redirects to enter password page
     * - enter password page => check to send new details
     */
    e.preventDefault();
    setLoading(true);
    const newDataObj = clearEmpty(getUpdatedOnly(userInfo, initialUserInfo));
    const mappedData = {
      ...newDataObj,
      name: {
        en: userInfo.name_en,
        ar: userInfo.name_ar,
      },
      email: newDataObj.email,
      phone: newDataObj.phone,
      age: newDataObj.age,
      gender: newDataObj.gender,
    };

    agentsServices
      .updateAgent(user._id, mappedData)
      .then((data) => {
        console.log(data);
        dispatch(authActions.update({ profile: data.record }));
        toastPopup.success("Account Info Updated Successfully");
      })
      .catch(responseErrorToast)
      .finally(() => setLoading(false));
  };

  const imageUpload = useCallback(async () => {
    try {
      const formData = new FormData();
      formData.append("image", updateImage);

      if (typeof updateImage === "object") {
        setImageLoading(true);

        const clientImage = await agentsServices.uploadImage(formData);
        setUploadImage(updateImage);

        dispatch(authActions.updateImage(clientImage.record));
        toastPopup.success("Photo Updated Successfully");
      }
    } catch (e) {
      responseErrorToast(e);
    }
    setImageLoading(false);
  }, [dispatch, updateImage]);

  useEffect(() => {
    if (imgUpdated) {
      imageUpload();
    }
  }, [imageUpload, imgUpdated]);

  useEffect(() => {
    if (userInfo.country.index !== initialUserInfo.country.index)
      setUserInfo((prev) => ({ ...prev, city: null }));

    setCities(userInfo.country);
  }, [userInfo.country, setCities, initialUserInfo.country.index]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <form
      className="add-branch-form flex flex-col gap-4 py-5"
      onSubmit={updateDetails}
    >
      <div className="account-details-container flex flex-col gap-4">
        <div className="main-image-label cursor-pointer h-28 w-28">
          {imageLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {uploadImage && (
                <MainImage
                  src={
                    uploadImage
                      ? typeof uploadImage === "string"
                        ? `${uploadImage}`
                        : URL.createObjectURL(uploadImage)
                      : null
                  }
                  alt={userData?.name.en}
                  className="uploaded-img"
                  onClick={() => {
                    window.open(
                      uploadImage ? URL.createObjectURL(uploadImage) : null
                    );
                  }}
                />
              )}
            </>
          )}
          <input
            className="main-input-image"
            type="file"
            accept="image/*"
            name="upload-img"
            ref={ref}
            onChange={(e) => {
              setImgUpdated(true);
              setUpdateImage(e.target.files[0]);
            }}
            disabled={imageLoading}
          />
          <label
            className="vendor-label-image absolute inset-0 flex justify-center items-center hover:cursor-pointer hover:shadow-lg"
            onClick={imageUploader}
            htmlFor="upload-img"
          >
            <FontAwesomeIcon icon={faImage} />
          </label>
        </div>

        <div className="account-info-container max-w-sm mx-auto flex flex-col gap-6 justify-center w-full">
          {formData.map((formInput, index) => {
            return (
              <>
                <MainInput
                  key={formInput.name}
                  {...formInput}
                  disabled
                  toEdit={true}
                  state={userInfo}
                  setState={setUserInfo}
                />
              </>
            );
          })}

          <MainButton className="full-width" text={t("confirm")} />
          <MainButton
            className="w-full mx-auto "
            onClick={() => setShowPasswordModal(true)}
            type="button"
          >
            <FontAwesomeIcon icon={faLock} className="mx-2" />
            {t("Change Password")}
          </MainButton>
        </div>
      </div>
      <EditPassworModal
        previousEmail={userData.email}
        showPasswordModal={showPasswordModal}
        handleClosePasswordModal={handleClosePasswordModal}
      />
    </form>
  );
}

const getUpdatedOnly = (obj1, obj2) => {
  const editData = {};
  Object.keys(obj1).forEach((key) => {
    if (obj1[key] !== obj2[key]) {
      editData[key] = obj1[key];
    }
  });
  return editData;
};
