import api from "../api";

import { API } from "../../constants";

/*--- CLIENTS ---*/
const { CLIENTS } = API;

export const clientsServices = {
  createClient: async (obj) => {
    const { data } = await api.post(CLIENTS.CREATE, obj);
    return data;
  },

  getClient: async (params) => (await api.get(CLIENTS.GET, { params })).data,

  updateClient: async ({ _id, data }) =>
    (await api.put(CLIENTS.UPDATE, { ...data }, { params: { _id } })).data,

  removeClient: async (params) =>
    (await api.delete(CLIENTS.REMOVE, { params })).data,

  listAllClients: async (params) =>
    (await api.get(CLIENTS.LIST, { params })).data,
};
