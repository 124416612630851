import { ROUTES } from "app/constants";
import { selectAuth } from "app/store/slices/auth-slice";
import { MainButton } from "components";
import Modal from "components/Modal/Modal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function CreateMemberModal({ showModal, setShowModal }) {
  const user = useSelector(selectAuth);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal
      visible={showModal}
      onClose={() => setShowModal(false)}
      title="invite"
      className="flex flex-col gap-3 justify-center items-center py-5"
    >
      {user.profile.role !== "org" && (
        <MainButton
          className="w-full"
          onClick={() => {
            setShowModal(false);
            setTimeout(() => {
              navigate(ROUTES.SUBAGENTS.CREATE);
            }, 100);
          }}
        >
          {t("Add Sub Agent")}
        </MainButton>
      )}
      <MainButton
        className="w-full"
        onClick={() => {
          setShowModal(false);
          setTimeout(() => {
            navigate(ROUTES.CLIENTS.CREATE);
          }, 100);
        }}
      >
        {t("Add Client")}
      </MainButton>
    </Modal>
  );
}
export default CreateMemberModal;
