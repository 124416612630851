import { ROUTES } from "app/constants";
import { login } from "app/store/actions";
import { setRole } from "app/store/slices/auth-slice";
import { switchLang } from "app/utils/lang";
import { ReactComponent as VendorLogo } from "assets/VIP-ICON-SVG/VendorLogo.svg";
import { ReactComponent as VendorLogoOrange } from "assets/VIP-ICON-SVG/VendorLogoOrange.svg";
import { MainButton } from "components";
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";
import { MainInput } from "components/Inputs";
import { getInitialFormData } from "helpers/forms";
import { loginFormData, loginSchema } from "helpers/forms/login";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "./Login.scss";

export default function Login() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const [user, setUser] = useState({
    ...getInitialFormData(loginFormData),
    type: "",
  });

  function handleLoginCatch(e) {
    setLoading(false);
    setErrorList([e.response?.data?.error ?? e.error]);
  }

  async function loginHandler(e) {
    e.preventDefault();
    setErrorList([]);

    if (!user.type) return setErrorList(["Please select user type"]);

    const { error, value } = loginSchema.validate(user);
    setLoading(true);
    if (!error) {
      return login({ email: value.email, password: value.password })
        .then(() => {
          setLoading(false);
          window.location.href = "/";
        })
        .catch(handleLoginCatch);
    }

    setLoading(false);
    setErrorList(error.details.map((e) => e.message));
  }

  useEffect(() => {
    setErrorList([]);
  }, [user]);

  useEffect(() => {
    dispatch(setRole(user.type?._id));
  }, [dispatch, user.type]);

  return (
    <div className="login">
      <div className="login-logo">
        <VendorLogo className="admin-login-logo text-white w-96 h-96" />
      </div>

      <div className="login-form">
        <div className="app-logo-small">
          <VendorLogoOrange className="login-logo-small" />
        </div>
        <ToggleLangBtn />

        <form
          className="login-box app-card-shadow max-xs:!w-full max-xs:!shadow-none"
          onSubmit={loginHandler}
          noValidate
        >
          <p>{t("login")}</p>
          {/* select role */}

          <div className="login-user-type min-w-[7rem]  w-3/4 px-5 py-3 shadow rounded-lg">
            <p className="login-as">{t("loginAs")}</p>

            <MainInput
              type="multi-select"
              list={[
                { _id: "agent", name: { en: "Agent", ar: "عميل" } },
                { _id: "org", name: { en: "Organization", ar: "مؤسسة" } },
              ]}
              name="type"
              setState={setUser}
              identifier="name"
              isMulti={false}
              className="w-full"
              closeMenuOnSelect
            />
          </div>

          {loginFormData.map((formInput, index) => {
            return (
              <MainInput
                {...formInput}
                key={formInput.name}
                state={user}
                setState={setUser}
              />
            );
          })}

          <FormErrorMessage errorList={errorList} />
          <MainButton text={t("login")} loading={loading} type="submit" />
          <div className="text-primary hover:opacity-80 capitalize" dir="rtl">
            <Link to={ROUTES.FORGOT_PASSWORD}>{t("forgotPassword")}</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

const ToggleLangBtn = () => {
  const { i18n } = useTranslation();
  function changeLang(lang) {
    i18n.changeLanguage(lang);
    switchLang(lang);
  }
  return (
    <div className="lang">
      {localStorage.getItem("i18nextLng") === "en" ? (
        <button onClick={() => changeLang("ar")}>العربية</button>
      ) : (
        <button onClick={() => changeLang("en")}>English</button>
      )}
    </div>
  );
};
