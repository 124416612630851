import { ROUTES } from "app/constants";
import agentServices from "app/services";
import { selectAuth } from "app/store/slices/auth-slice";
import { getLocalizedWord } from "app/utils/lang";
import { CardContainer, MainButton } from "components";
import BreadCrumb from "components/Breadcrumb/Breadcrumb";
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";
import { MainInput } from "components/Inputs";
import { clearEmpty } from "helpers/clear-empty";
import { getInitialFormData } from "helpers/forms";
import { subAgentForm, subAgentSchema } from "helpers/forms/subagent";
import useCountriesArr from "helpers/useCountriesArr";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

function CreateSubAgent() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { cities, countries, setCities } = useCountriesArr();

  const auth = useSelector(selectAuth);

  const [loading, setLoading] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [user, setUser] = useState(
    getInitialFormData(subAgentForm({ cities, countries }))
  );
  console.log(id);
  const toEdit = !!id;
  const { data, error, isLoading, isValidating } = useSWR(
    toEdit ? ["edit-agent-", id] : null,
    ([key, id]) =>
      agentServices
        .getAgent({ _id: id })
        .then(({ record }) => record[0])
        .then((data) => {
          return {
            name: getLocalizedWord(data.name),
            email: data.email,
            password: data.password,

            address: data.address,
            gender: data.gender,
            country: {
              ...data.country,
              _id: data.country.index,
              name: { en: data.country.en, ar: data.country.ar },
            },
            city: {
              ...data.city,
              _id: data.city.index,
              name: { en: data.city.en, ar: data.city.ar },
            },
            phone: data.phone,
            credit: data.credit ?? 0,
          };
        }),
    {
      onSuccess: (data) => {
        setUser((prev) => ({
          ...prev,
          ...data,
        }));
      },
    }
  ); //TODO: useSWR
  console.log(data);
  const formData = subAgentForm({ cities, countries });

  async function registerHandler(e) {
    e.preventDefault();

    setErrorList([]);
    setLoading(true);
    const arabicReg = /[\u0621-\u064A]/g;
    const isArabic = (q) => arabicReg.test(q);
    const _userObj = clearEmpty(getUpdatedOnly(user, data));

    if (toEdit) {
      const newData = getUpdatedOnly(_userObj, data);

      try {
        const res = await agentServices.updateAgent(id, newData);
        navigate(ROUTES.SUBAGENTS.LIST);
      } catch (e) {
        setErrorList([e?.response?.data?.error ?? e?.error ?? e.message]);
      } finally {
        setLoading(false);
      }
      return;
    }
    const { value: _user, error } = subAgentSchema.validate(_userObj);

    if (error) {
      setErrorList(error.details.map((e) => e.message));

      setLoading(false);
      return 1;
    }

    const registerData = {
      ..._user,
      name: {
        [isArabic(_user["name"]) ? "ar" : "en"]: _user["name"],
      },
      parent: auth._id,
      children: [],
    };

    try {
      const res = await agentServices.createAgent(registerData);
      navigate(ROUTES.SUBAGENTS.LIST);
    } catch (e) {
      setErrorList([e?.response?.data?.error ?? e?.error ?? e.message]);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!data || user?.country?._id !== data?.country?.index)
      setUser((prev) => ({ ...prev, city: null }));
    setCities(user.country);
  }, [user.country, setCities, data]);

  return (
    <CardContainer title="sub_agent" className="max-w-4xl mx-auto">
      <BreadCrumb
        pathList={[{ title: "subAgent_many", link: ROUTES.SUBAGENTS.LIST }]}
      />
      <form
        className="flex flex-col p-5 gap-5 w-3/4 min-w-[200px] max-w-[30rem] mx-auto px-16"
        onSubmit={registerHandler}
        noValidate
      >
        {formData.map((formInput, index) => {
          return (
            <MainInput
              key={formInput.name}
              state={user}
              setState={setUser}
              {...formInput}
              pattern={formInput.name === "phone" ? "^01[0125][0-9]{8}$" : null}
            />
          );
        })}
        <FormErrorMessage errorList={errorList} />

        <MainButton text={t("confirm")} loading={loading} type="submit" />
      </form>
    </CardContainer>
  );
}

export default CreateSubAgent;

const getUpdatedOnly = (obj1, obj2) => {
  const editData = {};
  Object.keys(obj1).forEach((key) => {
    if (obj1[key] !== obj2[key]) {
      editData[key] = obj1[key];
    }
  });
  return editData;
};
