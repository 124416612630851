import { STORE } from "app/constants";
import store from "app/store";
import Joi from "joi";

const multiLangObj = Joi.object({
  en: Joi.string().required(),
  ar: Joi.string().required(),
}).required();

const name = multiLangObj;
const email = Joi.string()
  .email({ minDomainSegments: 2, tlds: { allow: ["com", "net"] } })
  .required();
const password = Joi.string()
  .pattern(
    new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"),"password didn't match the pattern"
  )
  .required();
const phone = Joi.string().pattern(new RegExp("^01[0125][0-9]{8}$")).optional();

const authSchema = {
  login: Joi.object({ email, password }),
  recoveryCode: Joi.object({ email }),
  resetPassword: Joi.object({
    email,
    newPassword: password,
  }),
};

const agentsSchema = {
  createAgent: Joi.object({
    name,
    email,
    password,
    phone,
    country: multiLangObj,
    city: multiLangObj,
    // age: Joi.number().integer().min(18).max(70).required(),
    credit: Joi.number().integer().min(0).max(100).required(),
    parent: Joi.string().required(),
    children: Joi.array().items(Joi.string()),
  }),
};

const clientsSchema = {
  createClient: Joi.object({
    name,
    profession: multiLangObj,
    // barcode: Joi.string().alphanum().required(),
    email,
    password,
    phone,
    age: Joi.number().integer().min(10).max(100).required(),
    gender: Joi.string().valid("male", "female").required(),
    location: Joi.object({
      lat: Joi.number().required(),
      long: Joi.number().required(),
    }).optional(),
    // org: Joi.string().required(),
  }),
};

const joinsSchema = {
  createJoin: Joi.object({
    name,
    email,
    password,
    phone,
    country: multiLangObj,
    city: multiLangObj,
    type: Joi.string().valid("agent", "client").required(),
  }),
};

const adsSchema = {
  createAd: Joi.object({
    name: Joi.string().required(),
    vendor: Joi.string().required(),
    link: Joi.string().required(),
    gender: Joi.string().valid("male", "female", "both").required(),
    country: multiLangObj,
    city: multiLangObj,
    notification: multiLangObj,
    startDate: Joi.date().min("now").iso().required(),
    endDate: Joi.date().min(Joi.ref("startDate")).iso().required(),
    type: Joi.string()
      .valid("notification", "banner", "popup", "appear-first")
      .required(),
  }),
};

const schemas = {
  ...authSchema,
  ...agentsSchema,
  ...clientsSchema,
  ...joinsSchema,
  ...adsSchema,
};

export default schemas;
