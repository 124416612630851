export const API = Object.freeze({
  AUTH: {
    LOGIN: "login",
    RECOVERY: "recovery",
    RESET: "resetPassword",
  },
  ADMINS: {
    GET: "admin/get",
  },
  AGENTS: {
    GET: "get",
    LIST: "list",
    CREATE: "create",
    IMG: "image",
    ADD_TO_NETWORK: "", //need to edit
    UPDATE: "update",
  },

  REQUESTS: {
    GET: "request/get",
    CREATE: "request/create",
    LIST: "request/list",
    UPDATE: "request/update",
    ACCEPT_SENT: "request/acceptSentCredit",
    ACCETP_WITHDRAW: "request/acceptWithdrawCredit",
    ACCEPT_TO_SEND: "request/acceptToSendCredit",
    ACCEPT_TO_WITHDRAW: "request/acceptToWithdrawCredit",
  },
  CLIENTS: {
    GET: "client/get",
    LIST: "client/list",
    CREATE: "client/create",
    REMOVE: "client/remove",
    UPDATE: "client/update",
  },
  BANNERS: {
    LIST: "banner/list",
  },
  JOINS: {
    CREATE: "join/create",
  },
  ADS: {
    GET: "ad/get",
    LIST: "ad/list",
    CREATE: "ad/create",
    IMG: "ad/image",
  },
  PAGES: {
    GET: "page/get",
    LIST: "page/list",
  },
});
