import { MainButton } from "components";

export const RequestButton = ({ children, ...props }) => (
  <MainButton
    className="flex flex-row flex-nowrap w-full md:w-fit whitespace-nowrap justify-center items-center"
    {...props}
  >
    {children}
  </MainButton>
);
