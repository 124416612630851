import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ROUTES } from "app/constants";
import agentServices from "app/services";
import { selectAuth } from "app/store/slices/auth-slice";
import { getLocalizedWord } from "app/utils/lang";
import classNames from "classnames";
import { IconButton } from "components";
import PropTypes from "prop-types";
import Barcode from "react-barcode";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function EmployeeCard({ item, refetch }) {
  const auth = useSelector(selectAuth);
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`${ROUTES.CLIENTS.EDIT}/${item._id}`);
  };

  const handleDelete = () => {
    agentServices.removeClient({ _id: item._id, org: auth._id }).finally(() => {
      if (refetch) refetch();
    });
  };

  return (
    <article
      className="relative flex flex-col shadow-lg p-4 rounded-xl gap-5"
      key={item._id}
    >
      <div className="absolute -right-2 -top-2">
        <div
          className={classNames(
            { "bg-green-700": item.isActive },
            "h-4 w-4 rounded-full border-white border-[1px] ring-[2px] ring-black/30 p-1"
          )}
        ></div>
      </div>
      <div className="flex gap-2 justify-end border-b border-b-slate-200">
        <IconButton
          icon={faEdit}
          variant="primary"
          size="md"
          onClick={handleEdit}
        />
        <IconButton
          icon={faTrash}
          variant="danger"
          size="md"
          onClick={handleDelete}
        />
      </div>
      <div className="flex flex-row gap-4">
        <div className="w-16 overflow-hidden h-16 rounded-full ring-cyan-700/40 ring aspect-square bg-primary/70">
          <img
            src={item?.image?.Location}
            className="object-cover w-full h-full"
            alt={item?.name?.en}
          />
        </div>
        <div className="flex flex-col gap-3">
          <h5>{getLocalizedWord(item?.name, "Employee Name")}</h5>
        </div>
      </div>
      <div className="mx-auto mt-3">
        {<Barcode value={item.barcode} width={1.85} height={25} />}
      </div>
    </article>
  );
}

EmployeeCard.prototype = {
  item: {
    _id: PropTypes.string,
  },
};

export default EmployeeCard;
