import { authServices } from "app/services/modules/auth";
import { setRole } from "app/store/slices/auth-slice";
import toastPopup from "app/utils/toastPopup";
import { MainButton } from "components";
import { MainInput } from "components/Inputs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function NewPasswordView({ input, setInput }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const roleName = input?.role?._id;

  async function handleConfirmPassword() {
    if (input.password.toString() === input["confirmPassword"].toString()) {
      dispatch(setRole(roleName));
      setLoading(true);

      const res = await authServices
        .resetPassword(
          { email: input.email, newPassword: input.password },
          input.token
        )
        .then(() => {
          toastPopup.success("success");
          setTimeout(() => {
            navigate("/login");
          }, 1000);
        })
        .catch((e) => {
          toastPopup.error(e.response?.data ?? "Something went wrong!");
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 300);
        });
    } else {
      toastPopup.error("The passwords didn't match");
    }
  }
  return (
    <>
      <h3 className="capitalize">{t("forgotPassword")}</h3>
      <p className="w-2/3 my-3 text-center">{t("New_password")}</p>

      <MainInput
        state={input}
        setState={setInput}
        name="password"
        type="password"
        className="w-4/5"
      />
      <MainInput
        state={input}
        setState={setInput}
        name="confirmPassword"
        type="password"
        className="w-4/5"
      />

      <MainButton
        text="send"
        type="submit"
        loading={loading}
        className="w-4/5 mt-8"
        onClick={handleConfirmPassword}
      />
    </>
  );
}
export default NewPasswordView;
