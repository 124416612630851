import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectAuth } from "app/store/slices/auth-slice";
import { getLocalizedNumber, getLocalizedWord } from "app/utils/lang";
import Tabs from "components/Tabs/Tabs";
import { useSelector } from "react-redux";
import { AccountAgents } from "./AccountAgents";
import { AccountDetails } from "./AccountDetails";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { CardContainer } from "components";
import MainImage from "components/MainImage/MainImage";

const Account = () => {
  const user = useSelector(selectAuth)?.profile;
  const { t } = useTranslation();

  return (
    <CardContainer>
      <header className="flex flex-row justify-between items-center border-b-2 pb-4">
        <div className="flex flex-row gap-4">
          <div className="aspect-square max-w-full w-28 h-28 rounded-full overflow-hidden bg-primary/20 border-2 border-slate-400 ">
            {user?.image ? (
              <MainImage
                src={user?.image?.Location}
                alt={getLocalizedWord(user?.name)}
                className="max-h-full max-w-full object-cover h-full w-full"
              />
            ) : (
              <div className="p-5 justify-center items-center flex">
                <FontAwesomeIcon
                  icon={faUser}
                  size="xl"
                  className="text-slate-600 h-3/4 w-3/4 "
                />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-2 my-1">
            <h4 className="font-bold text-primary drop-shadow">
              {getLocalizedWord(user.name)}
            </h4>
            <p className="text-xl capitalize">
              {t("credit", { count: user.credit })}{" "}
              <span className="ml-2">{getLocalizedNumber(user.credit)}</span>
            </p>
            <p className={classNames({ hidden: !user.city && !user.city })}>
              <span>{getLocalizedWord(user.city)}</span>, &nbsp;
              <span>{getLocalizedWord(user.country)}</span>
            </p>
          </div>
        </div>
      </header>
      <AccountDetails />
    </CardContainer>
  );
};

export default Account;
