import { ROUTES } from "app/constants";
import { logout } from "app/store/actions";
import { selectAuth } from "app/store/slices/auth-slice";
import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children, role = "auth" }) => {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);

  const isLoggedIn = !!auth.token;
  const hasProfile = !!auth.profile;

  const userRole = auth.profile?.role;
  const isActive = auth.profile?.isActive;

  const isAuthPermitted = role === "auth";
  const isRolePermitted = userRole === role;

  useLayoutEffect(() => {
    if (!isLoggedIn) dispatch(logout());
    if (!isRolePermitted && !isAuthPermitted) window.location.href = "/";

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  if (!isLoggedIn) return <Navigate to={ROUTES.LOGIN} />;

  // __________________________________
  // if logged in

  // but is not active / has no access to this page
  if (hasProfile && !isActive) return <Navigate to={ROUTES.BLOCKED} />;

  // but is not generally permitted and his role is not permitted to access it
  if (!isRolePermitted && !isAuthPermitted) return <Navigate to="/" />;

  // logged in and has an active profile with the permitted role
  return children;
};
