import { ROUTES } from "app/constants";
import i18n from "app/locales/i18n";
import agentServices from "app/services";
import { selectAuth } from "app/store/slices/auth-slice";
import { useAddressList } from "app/utils/countries";
import toastPopup from "app/utils/toastPopup";
import { CardContainer, MainButton } from "components";
import { MainInput } from "components/Inputs";
import { getCities, getCountries } from "country-city-multilanguage";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

function CreateClient({ client: clientData }) {
  const { clientId } = useParams();
  const auth = useSelector(selectAuth);
  const role = auth.profile.role;

  const isEditMode = !!clientId;
  useSWR(
    isEditMode ? ["client-", clientId] : null,
    ([key, _id]) =>
      agentServices
        .getClient({ _id, [role]: auth._id })
        .then(({ record }) => record[0] ?? record),
    {
      onSuccess(data) {
        const { name, country, city, ...rest } = data;
        const clientData = {
          ...rest,
          name_en: name.en ?? "",
          name_ar: name.ar ?? "",
          country: country[lang],
          city: city[lang],
        };
        setCurrentClient(clientData);
        setUser(clientData);
      },
    }
  );

  const { t } = useTranslation();

  const [countries, cities, setCountryId] = useAddressList();
  const [currentClient, setCurrentClient] = useState(clientData);

  const navigate = useNavigate();
  const [errorList, setErrorList] = useState([]);
  const [uploadImage, setUploadImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [user, setUser] = useState(
    currentClient ?? {
      name_en: "",
      email: "",
      password: "",
      gender: "male",
    }
  );
  const [loading, setLoading] = useState(false);
  const lang = i18n.language;
  const formData = [
    { name: "name_en", type: "text", required: true },
    { name: "name_ar", type: "text", required: true },
    { name: "email", type: "email", required: true },
    { name: "password", type: "password", required: true },
    { name: "phone", type: "phone", required: false },
    { name: "age", type: "number", required: false },
    {
      name: "country",
      type: "list",
      list: countries,
      identifier: "name",
      required: true,
    },
    {
      name: "city",
      type: "list",
      list: cities,
      identifier: "name",
      required: true,
    },
    {
      name: "gender",
      type: "list",
      list: [
        { name: { en: "male", ar: "ذكر" } },
        { name: { en: "female", ar: "أنثى" } },
      ],
      identifier: "name",
      required: false,
    },
  ];
  async function registerHandler() {
    const countryList_AR = getCountries("ar");
    const countryList_EN = getCountries("en");
    let currentCountryIdx;
    let currentCityIdx;
    if (lang === "ar") {
      currentCountryIdx = countryList_AR.find(
        (country) => country?.label === user.country
      )?.index;
    } else {
      currentCountryIdx = countryList_EN.find(
        (country) => country?.label === user.country
      )?.index;
    }

    const cityList_AR = currentCountryIdx
      ? getCities(currentCountryIdx, "ar")
      : [];
    const cityList_EN = currentCountryIdx
      ? getCities(currentCountryIdx, "en")
      : [];

    if (lang === "ar") {
      currentCityIdx = cityList_AR.find(
        (city) => city?.label === user.city
      )?.value;
    } else {
      currentCityIdx = cityList_EN.find(
        (city) => city?.label === user.city
      )?.value;
    }

    const registerData = {
      name: {
        ar: user.name_ar,
        en: user.name_en,
      },
      email: user.email,
      password: user.password,
      phone: user.phone,
      age: user.age,
      credit: user.credit,
      gender: user.gender,
      country: {
        ar: countryList_AR.find(
          (country) => country.index === currentCountryIdx
        )?.label,
        en: countryList_EN.find(
          (country) => country.index === currentCountryIdx
        )?.label,
      },
      city: {
        ar: cityList_AR.find((city) => city.value === currentCityIdx)?.label,
        en: cityList_EN.find((city) => city.value === currentCityIdx)?.label,
      },
      [role]: auth._id,
    };
    const obj = _.omitBy(
      registerData,
      (v, k) => _.isNil(v) || currentClient[k] === v
    );

    if (isEditMode) {
      try {
        const res = await agentServices.updateClient({
          _id: clientId,
          data: obj,
        });
        toastPopup.success("Client updated");
        navigate(ROUTES.SUBAGENTS.LIST);
      } catch (e) {
        toastPopup.error(
          e.response?.data?.error ?? e.message ?? "Something went wrong"
        );
      }
      return;
    }

    try {
      if (isEditMode) return;
      const res = await agentServices.createClient(registerData);
      toastPopup.success("Client added ");
      navigate(ROUTES.SUBAGENTS.LIST);
    } catch (e) {
      toastPopup.error(
        e.response?.data?.error ?? e.message ?? "Something went wrong"
      );
    }
  }

  useEffect(() => {
    if (!user.country) return;

    const idx = countries.findIndex(({ name }) => name[lang] === user.country);
    if (idx >= 0) setCountryId(countries[idx].index);
  }, [user.country, countries, lang, setCountryId]);

  return (
    <CardContainer
      title={`${isEditMode ? "edit" : "create"}Client`}
      className="max-w-4xl mx-auto"
    >
      <form
        autoComplete="off"
        onSubmit={(e) => e.preventDefault()}
        className="flex flex-col p-5 gap-5 w-3/4 min-w-[200px] mx-auto px-16"
      >
        {formData.map((formInput, index) => {
          return (
            <MainInput
              key={formInput.name}
              name={formInput.name}
              type={formInput.type}
              list={formInput.list ?? []}
              required={formInput.required}
              identifier={formInput.identifier}
              state={user}
              setState={setUser}
              pattern={formInput.name === "phone" ? "^01[0125][0-9]{8}$" : null}
            />
          );
        })}
        <MainButton
          text={t("confirm")}
          loading={loading}
          onClick={registerHandler}
        />
      </form>
    </CardContainer>
  );
}

export default CreateClient;
