import ReactDOM from "react-dom/client";

import { Provider } from "react-redux";
import store from "./app/store";

import { RouterProvider } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import { router } from "./app/router";

import "react-toastify/dist/ReactToastify.css";
import "./styles/index.scss";
import "./components/Navbar/Navbar.scss";
import 'animate.css/animate.min.css';
import { I18nextProvider } from "react-i18next";
import i18n from "app/locales/i18n";
import { HelmetProvider } from "react-helmet-async";

const helmetContext = {};

const root = ReactDOM.createRoot(document.getElementById("root"));
document.getElementById("loader-container")?.remove();

root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <HelmetProvider context={helmetContext}>
        <RouterProvider router={router} />
        <ToastContainer limit={2} />
      </HelmetProvider>
    </I18nextProvider>
  </Provider>
);
