import {
  Account,
  Ads,
  Chat,
  CreateAd,
  Employees,
  IncomingView,
  Login,
  OutgoingView,
  PreviousAds,
  Requests,
  SubAgents,
} from "pages";
import Client, { loader as clientLoader } from "pages/Client/Client";
import BlockedUserPage from "pages/Error/BlockedUser";
import NotFoundPage from "pages/Error/NotFoundPage";
import ForgetPasswordPage from "pages/ForgetPassword/ForgetPassword";
import CreateClient from "pages/SubAgents/_views/CreateClient";
import CreateSubAgent from "pages/SubAgents/_views/CreateSubAgent";
import { createBrowserRouter, Navigate } from "react-router-dom";
import App from "../../App";
import { ROUTES } from "../constants";
import { GuestRoute } from "./Routes/GuestRoute";
import { ProtectedRoute } from "./Routes/ProtectedRoute";

const publicRoutes = [
  { path: ROUTES.BLOCKED, element: <BlockedUserPage /> },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: (
      <GuestRoute>
        <ForgetPasswordPage />
      </GuestRoute>
    ),
  },
  {
    path: ROUTES.LOGIN,
    element: (
      <GuestRoute>
        <Login />
      </GuestRoute>
    ),
  },
];

const authRoutes = [
  { path: ROUTES.SUBAGENTS.LIST, element: <SubAgents />, role: "agent" },
  { path: ROUTES.EMPLOYEES.LIST, element: <Employees />, role: "org" },
  { path: ROUTES.SUBAGENTS.CREATE, element: <CreateSubAgent />, role: "agent" },
  {
    path: ROUTES.SUBAGENTS.EDIT + "/:id",
    element: <CreateSubAgent />,
    role: "agent",
  },
  {
    path: ROUTES.CLIENTS.LIST + "/:clientId",
    element: <Client />,
    loader: clientLoader,
  },
  { path: ROUTES.CLIENTS.CREATE, element: <CreateClient /> },
  { path: ROUTES.CLIENTS.EDIT + "/:clientId", element: <CreateClient /> },
  {
    path: ROUTES.REQUESTS,
    element: <Requests />,
    role: "agent",
    children: [
      {
        index: true,
        element: <Navigate to={ROUTES.REQUESTS_VIEWS.INCOMING} />,
      },
      {
        path: ROUTES.REQUESTS_VIEWS.INCOMING,
        element: <IncomingView />,
        role: "agent",
      },
      {
        path: ROUTES.REQUESTS_VIEWS.OUTGOING,
        element: <OutgoingView />,
        role: "agent",
      },
    ],
  },
  { path: ROUTES.ACCOUNT, element: <Account /> },
  { path: ROUTES.ADS.MAIN, element: <Ads />, role: "agent" },
  { path: ROUTES.ADS.CREATE, element: <CreateAd />, role: "agent" },
  { path: ROUTES.ADS.LIST, element: <PreviousAds />, role: "agent" },
  { path: ROUTES.CHAT, element: <Chat /> },

  {
    index: true,
    element: <Navigate to={ROUTES.ACCOUNT} />,
  },
];

const protectedRoutes = authRoutes.map(
  ({ element, role, ...routeObject }, idx) => {
    return {
      ...routeObject,
      element: (
        <ProtectedRoute role={role} key={routeObject.path ?? idx}>
          {element}
        </ProtectedRoute>
      ),
    };
  }
);

export const router = createBrowserRouter([
  ...publicRoutes,

  {
    path: "/",
    element: (
      <ProtectedRoute role="auth">
        <App />
      </ProtectedRoute>
    ),

    errorElement: <NotFoundPage />,
    children: [...protectedRoutes],
  },
]);
