import { ProtectedComponent } from "app/router/Routes/ProtectedComponent";
import { t } from "i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { ROUTES } from "../../app/constants";
import i18n from "../../app/locales/i18n";
import { logout } from "../../app/store/actions";
import { switchLang } from "../../app/utils/lang";
import { ReactComponent as NavbarLogo } from "../../assets/VIP-ICON-SVG/NavbarLogo.svg";
import { ReactComponent as BurgerMenuIcon } from "../../assets/VIP-ICON-SVG/burgerMenu.svg";

import SideNav from "./SideNav/SideNav";
import { useTranslation } from "react-i18next";

export default function Navbar() {
  const navigate = useNavigate();
  const [viweAccountMenu, setViweAccountMenu] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);

  const lang = i18n.language;
  const { t } = useTranslation();

  const navItems = [
    { link: ROUTES.EMPLOYEES.LIST, title: "employee_other", role: "org" },
    { link: ROUTES.SUBAGENTS.LIST, title: "subAgent_many", role: "agent" },
    { link: ROUTES.REQUESTS, title: "requests", role: "agent" },
    { link: ROUTES.ADS.MAIN, title: "Ads", role: "agent" },
    { link: ROUTES.CHAT, title: "chat" },
    { link: ROUTES.ACCOUNT, title: "myAccount" },
    {
      link: "/logout",
      title: "logout",
      onClick: (e) => {
        logoutHandler(e);
      },
    },
  ];

  function toggleSideMenu() {
    setShowSideMenu((prevState) => !prevState);
  }

  function changeLang(lang) {
    i18n.changeLanguage(lang);
    switchLang(lang);
  }

  function logoutHandler(e) {
    e.preventDefault();

    logout();
    setViweAccountMenu((prevState) => !prevState);
  }

  return (
    <nav className="top-nav">
      <BurgerMenuIcon className="burger-menu-icon" onClick={toggleSideMenu} />
      <NavbarLogo
        className="app-logo"
        onClick={() => {
          navigate("/");
        }}
      />
      <ul className="nav-menu">
        {navItems.map((item, idx) => (
          <li key={idx} className="capitalize nav-item">
            <ProtectedComponent role={item.role ?? "auth"}>
              <NavLink
                to={item.link}
                className={(navData) =>
                  navData.isActive ? "active nav-link" : "nav-link"
                }
                onClick={item.onClick}
              >
                {t(item.title)}
              </NavLink>
            </ProtectedComponent>
          </li>
        ))}
      </ul>

      <div className="notifictation-language">
        <button
          className="lang-btn"
          onClick={
            lang === "en"
              ? () => {
                  changeLang("ar");
                }
              : () => {
                  changeLang("en");
                }
          }
        >
          {t("lang")}
        </button>
      </div>
      {showSideMenu && <SideNav onToggle={toggleSideMenu} items={navItems} />}
    </nav>
  );
}
