import {
  citySchema,
  countrySchema,
  emailSchema,
  passwordSchema,
  phoneSchema,
} from "helpers/validations/common";
import { t } from "i18next";
import Joi from "joi";

export const subAgentSchema = Joi.object({
  name: Joi.string()
    .required()
    .messages({
      "string.empty": t("name_notEmpty"),
      "any.required": t("name_required"),
    }),
  email: emailSchema,
  password: passwordSchema,
  phone: phoneSchema.optional(),
  gender: Joi.string()
    .valid("male", "female", "both")
    .required()
    .messages({
      "string.empty": t("gender_notEmpty"),
      "any.required": t("gender_required"),
    }),

  credit: Joi.number()
    .required()
    .messages({
      "string.empty": t("credit_notEmpty"),
      "any.required": t("credit_required"),
    }),
  // address: Joi.string().optional().messages({
  //   "string.empty": t("address_notEmpty"),
  //   "any.required": t("address_required"),
  // }),
  country: countrySchema,
  city: citySchema,
  // at this point lat and long are not required "in mobile app"
  // lat: Joi.any()
  //   .required()
  //   .messages({
  //     "string.empty": t("lat_notEmpty"),
  //     "any.required": t("lat_required"),
  //   }),
  // long: Joi.any()
  //   .required()
  //   .messages({
  //     "string.empty": t("lng_notEmpty"),
  //     "any.required": t("lng_required"),
  //   }),
});

export const subAgentUpdateSchema = Joi.object({
  name: Joi.string().messages({
    "string.empty": t("name_notEmpty"),
    "any.required": t("name_required"),
  }),
  email: emailSchema.optional(),
  phone: phoneSchema.optional(),
  gender: Joi.string()
    .valid("male", "female", "both")
    .messages({
      "string.empty": t("gender_notEmpty"),
      "any.required": t("gender_required"),
    }),

  credit: Joi.number().messages({
    "string.empty": t("credit_notEmpty"),
    "any.required": t("credit_required"),
  }),
 
  country: countrySchema,
  city: citySchema,
 
});

export const subAgentForm = ({ countries = [], cities = [] }) => [
  { name: "name", type: "text", required: true },
  { name: "email", type: "email", required: true },
  { name: "password", type: "password", required: true },
  // { name: "address", type: "text", required: false }, // not sent from BE so it's not existed
  {
    name: "gender",
    type: "select",
    list: [{ name: "male" }, { name: "female" }],
    identifier: "name",
    required: true,
  },
  {
    name: "country",
    type: "multi-select",
    isMulti: false,
    list: countries,
    identifier: "name",
    required: true,
  },
  {
    name: "city",
    type: "multi-select",
    isMulti: false,
    list: cities,
    identifier: "name",
    required: true,
  },
  { name: "phone", type: "tel", required: false },
  // { name: "link", type: "text", required: false },
  // { name: "lat", type: "text", required: true },
  // { name: "long", type: "text", required: true },
  { name: "credit", type: "number", required: true },
];
