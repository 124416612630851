import { adminsServices } from "./modules/admins";
import { adsServices } from "./modules/ads";
import { agentsServices } from "./modules/agents";
import { authServices } from "./modules/auth";
import { bannersServices } from "./modules/banners";
import { clientsServices } from "./modules/clients";
import { joinsServices } from "./modules/joins";
import { pagesServices } from "./modules/pages";
import { requestsServices } from "./modules/requests";

const agentServices = {
  ...authServices,
  ...agentsServices,
  ...clientsServices,
  ...bannersServices,
  ...joinsServices,
  ...pagesServices,
  ...adsServices,
  ...adminsServices,
  ...requestsServices,
};

export default agentServices;
