import { ROUTES } from "app/constants";
import i18n from "app/locales/i18n";
import { switchLang } from "app/utils/lang";
import { ReactComponent as VendorLogo } from "assets/VIP-ICON-SVG/VendorLogo.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ConfirmCodeView from "./_view/ConfirmCodeView";
import NewPasswordView from "./_view/NewPasswordView";
import SendRecoveryMailView from "./_view/SendRecoveryMailView";

function LanguageToggle() {
  function changeLang(lang) {
    i18n.changeLanguage(lang);
    switchLang(lang);
  }
  
  return (
    <div className="absolute right-16 top-16">
      {localStorage.getItem("i18nextLng") === "en" ? (
        <button onClick={() => changeLang("ar")}>العربية</button>
      ) : (
        <button onClick={() => changeLang("en")}>English</button>
      )}
    </div>
  );
}

function ForgetPassword() {
  const [input, setInput] = useState({ step: 0 });
  const { t } = useTranslation();

  function render() {
    switch (input.step) {
      case 0:
        return <SendRecoveryMailView input={input} setInput={setInput} />;
      case 1:
        return <ConfirmCodeView input={input} setInput={setInput} />;
      case 2:
        return <NewPasswordView input={input} setInput={setInput} />;
      default:
        return <SendRecoveryMailView input={input} setInput={setInput} />;
    }
  }

  return (
    <div className="h-screen w-screen flex flex-row justify-center items-center max-md:flex-col ">
      <div className="w-1/2 h-full bg-primary flex justify-center items-center max-md:hidden">
        <VendorLogo className="p-5 max-w-xs w-96 h-96 text-white" />
      </div>

      <LanguageToggle />
      <div className="md:w-1/2  justify-center items-center flex flex-col relative max-md:h-full ">
        <div className="w-4/5 min-w-fit shadow-lg rounded-2xl p-5 flex flex-col items-center justify-between h-fit gap-4 max-w-2xl py-16">
          {render()}
          <div className="text-primary hover:opacity-80">
            <Link to={ROUTES.LOGIN}>{t("login")}</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
