import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { ROUTES } from "app/constants";
import agentServices from "app/services";
import { selectAuth } from "app/store/slices/auth-slice";
import { IconButton, MainButton } from "components";
import { EmployeeCard } from "components/Cards";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Modal from "components/Modal/Modal";
import NoData from "components/NoData/NoData";
import {
  PageQueryWrapper,
  SearchBar,
  SearchProvider,
} from "components/PageQueryContainer/PageQueryContext";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

const LIMIT = 9;

function Employees(props) {
  const user = useSelector(selectAuth);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const initialQueryState = useMemo(
    () => ({
      page: 1,
      limit: LIMIT,
      org: user._id,
    }),
    [user]
  );
  const [queryParams, setQueryParams] = useState(initialQueryState);
  const [showModal, setShowModal] = useState(false);
  const {
    data: employeesData,
    isLoading,
    mutate,
  } = useSWR(["all-employees", queryParams], ([key, query]) =>
    agentServices.listAllClients(query)
  );
  const { records: employees = undefined, counts: employeesCount } =
    employeesData ?? {};
  console.log(employeesData, employeesCount);

  const listRender = () => {
    if (isLoading)
      return (
        <div className="col-span-3">
          <LoadingSpinner />
        </div>
      );
    if (employees?.length)
      return employees?.map((item) => (
        <EmployeeCard key={item._id} item={item} refetch={mutate} />
      ));
    return (
      <div className="col-span-3">
        <NoData />
      </div>
    );
  };

  return (
    <SearchProvider
      itemsCount={employeesCount}
      listRenderFn={listRender}
      queryParams={queryParams}
      withSideFilter={false}
      initialFilters={initialQueryState}
      setQueryParams={setQueryParams}
      limit={LIMIT}
    >
      <SearchBar />
      <PageQueryWrapper>
        <div className="flex ltr:flex-row gap-20 py-3 px-4 border border-primary/25 rounded-lg mb-4 justify-between">
          <h5 className="capitalize font-semibold">
            {t("employee", { count: employeesCount ?? 0 })} ({employeesCount})
          </h5>
          <IconButton
            icon={faSquarePlus}
            variant="primary"
            size="2x"
            className=""
            onClick={() => setShowModal(true)}
          />
        </div>
      </PageQueryWrapper>
      <Modal
        visible={showModal}
        onClose={() => setShowModal(false)}
        title="invite"
        className="flex flex-col gap-3 justify-center items-center"
      >
        {user.profile.role !== "org" && (
          <MainButton
            className="w-full"
            onClick={() => navigate(ROUTES.SUBAGENTS.CREATE)}
          >
            Add sub-agent
          </MainButton>
        )}
        <MainButton
          className="w-full"
          onClick={() => navigate(ROUTES.CLIENTS.CREATE)}
        >
          {t("addClient")}
        </MainButton>
      </Modal>
    </SearchProvider>
  );
}

Employees.propTypes = {};

export default Employees;
