import { STORE } from "app/constants";
import { logout } from "app/store/actions";
import { getLocalStorageKey } from "app/store/slices/auth-slice";
import { useLayoutEffect } from "react";
import { Navigate } from "react-router-dom";

export const GuestRoute = ({ children }) => {
  const authTokenExisted = getLocalStorageKey(STORE.AUTH.TOKEN);

  useLayoutEffect(() => {
    if (!authTokenExisted) logout();
  }, [authTokenExisted]);

  if (!authTokenExisted) return children;
  return <Navigate to="/" />;
};
