import { ReactComponent as ErrorIllustration } from "assets/images/error404.svg";
import { Link } from "react-router-dom";

function NotFoundPage() {
  return (
    <div className="flex flex-col justify-center items-center h-full max-h-screen w-full p-5">
      <ErrorIllustration className="max-w-[60%]" />
      <p>
        Go to{" "}
        <Link to="/" className="text-blue-700 hover:text-blue-500">
          home page
        </Link>
      </p>
    </div>
  );
}

export default NotFoundPage;
