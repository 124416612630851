import { ILocalizedString } from "types/global";
import i18n from "../locales/i18n";

export function checkFixLang(lang) {
  const dir = lang === "ar" ? "rtl" : "ltr";
  document.documentElement.dir = dir;
  document.documentElement.lang = lang;
  document.body.dir = dir;
  document.body.lang = lang;
}

export function switchLang(lang) {
  localStorage.setItem("lang", lang);
  checkFixLang(lang);
  window.location.reload();
}

export function getLocalizedWord(
  word: ILocalizedString | string | undefined,
  defaultWord = ""
): string {
  const lang = i18n.language;

  if (!word) return defaultWord;
  if (typeof word === "string") return word;
  return word?.[lang] ?? word?.en ?? word?.ar ?? defaultWord;
}

export function getLocalizedNumber(price = 0, isPrice = false) {
  const lang = i18n.language;

  return new Intl.NumberFormat(`${lang}-EG`, {
    ...(isPrice && { style: "currency", currency: "EGP" }),
    maximumFractionDigits: 2,
  }).format(price);
}
