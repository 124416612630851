export const clearEmpty = (obj) => {
  const result = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (typeof value === "object" && !Array.isArray(value)) {
        result[key] = clearEmpty(value);
      } else if (value !== null && value !== undefined && value !== "") {
        result[key] = value;
      }
    }
  }
  return result;
};
