import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import NoData from "components/NoData/NoData";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import agentServices from "../../app/services";
import { selectAuth } from "../../app/store/slices/auth-slice";
import { IconButton, MainButton } from "../../components";
import AgentCard from "./components/AgentCard";
import CreateMemberModal from "./components/CreateMemberModal";
import ScanCodeModal from "./components/ScanCodeModal";

import "./SubAgents.scss";
import { getLocalizedNumber } from "app/utils/lang";

const allAgentsFetcher = ([key, user]) =>
  agentServices
    .listAllAgents({ parent: user._id })
    .then(({ record }) => record);

const SubAgentsPage = () => {
  const user = useSelector(selectAuth);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [showCodeModal, setShowCodeModal] = useState(false);

  const {
    data: subAgents,
    error,
    isLoading,
  } = useSWR(["all-sub-agents", user], allAgentsFetcher);

  const listRender = () => {
    if (isLoading) return <LoadingSpinner />;
    if (error) return <NoData text="Something went wrong!" />;
    if (subAgents?.length)
      return subAgents.map((item) => <AgentCard key={item._id} agent={item} />);
    else return <NoData />;
  };

  const closeCodeModal = () => setShowCodeModal(false);

  return (
    <main className="sub-agents-page flex flex-col max-md:p-4 p-8">
      <header className="flex flex-col">
        <div className="flex flex-row gap-20 justify-between">
          <h5 className="font-semibold capitalize">
            {t("subAgent_many", { count: subAgents?.length ?? 0 })} (
            {getLocalizedNumber(subAgents?.length ?? 0)})
          </h5>
          <IconButton
            icon={faSquarePlus}
            variant="primary"
            size="xl"
            onClick={() => setShowModal(true)}
          />
        </div>
        <div className="flex flex-row w-full justify-end py-3">
          <MainButton
            className="w-fit !text-sm !py-2 !h-auto capitalize"
            onClick={() => setShowCodeModal(true)}
          >
            {t("Barcode")}
          </MainButton>
        </div>
        <h6 className="font-semibold">
          {t("credit", { count: user.profile?.credit ?? 0 })} (
          {getLocalizedNumber(user.profile?.credit ?? 0)})
        </h6>
      </header>

      <section className="flex flex-row gap-4 flex-wrap shadow rounded-2xl p-5 mt-4 justify-center items-center">
        {listRender()}
      </section>

      {/* MODALS */}
      <CreateMemberModal {...{ showModal, setShowModal }} />
      <ScanCodeModal
        {...{
          showCodeModal,
          closeCodeModal,
        }}
      />
    </main>
  );
};

export default SubAgentsPage;
