import {
  faArrowRightToBracket,
  faArrowTrendDown,
  faArrowTrendUp,
  faArrowUpFromBracket,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import agentServices from "app/services";
import { selectAuth } from "app/store/slices/auth-slice";
import { getLocalizedWord } from "app/utils/lang";
import toastPopup from "app/utils/toastPopup";
import classNames from "classnames";
import { MainButton } from "components";
import { RequestCard } from "components/Cards";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Modal from "components/Modal/Modal";
import { useState } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import { RequestButton } from "../_components/RequestButton";
import NoData from "components/NoData/NoData";
import { useTranslation } from "react-i18next";

export default function OutgoingView() {
  const user = useSelector(selectAuth);
  const { t } = useTranslation();

  const [requests, setRequests] = useState([]);
  const [requestModal, setRequestModal] = useState({});
  const [selectedAgent, setSelectedAgent] = useState("");
  const { data: agentsListData } = useSWR(
    { parent: user._id },
    agentServices.listAllAgents
  );
  const agentsList = agentsListData?.records ?? agentsListData?.record;
  const { isLoading, mutate: invalidateRequestList } = useSWR(
    "list-all-out-reqs",
    agentServices.listAllOutgoingRequests,
    {
      onSuccess: (data) => {
        if (data.records || data.record)
          setRequests(data.records ?? [data.record]);
      },
      keepPreviousData: true,
      revalidateOnMount: true,
      revalidateOnFocus: true,
    }
  );

  function handleRequestAction(type) {
    setSelectedAgent("");
    setRequestModal({ visible: true, type });
  }

  function handleConfirmRequest() {
    const requestBody =
      requestModal.type === "request" || requestModal.type === "return"
        ? {
            from: user._id,
            to: user.profile.parent._id,
            type: requestModal.type,
            credit: requestModal.credit ?? 0,
          }
        : {
            from: user._id,
            to: selectedAgent,
            type: requestModal.type,
            credit: requestModal.credit ?? 0,
          };
    agentServices.createRequest(requestBody).then(() => {
      toastPopup.success("success");
      setRequestModal({});
      invalidateRequestList();
    });
  }

  if (isLoading)
    return (
      <div className="flex justify-center items-center w-full h-full">
        <LoadingSpinner />
      </div>
    );

  if (!isLoading && !requests.length)
    return (
      <div className="p-8 flex justify-center items-center w-full h-full">
        <NoData />
      </div>
    );
  return (
    <main className="app-card">
      <div className="mt-3 mb-8 flex flex-col md:flex-row w-full gap-4 justify-between items-center flex-wrap">
        <RequestButton
          disabled={!user.profile.children.length}
          onClick={() => handleRequestAction("send")}
        >
          {t("Send credit")}
          <span className="mx-1">
            <FontAwesomeIcon icon={faArrowUpFromBracket} />
          </span>
        </RequestButton>
        <RequestButton
          disabled={!user.profile.children.length}
          onClick={() => handleRequestAction("withdraw")}
        >
          {t("Withdraw credit")}
          <span className="mx-1">
            <FontAwesomeIcon icon={faArrowTrendDown} />
          </span>
        </RequestButton>
        <RequestButton
          disabled={!user.profile.parent}
          onClick={() => handleRequestAction("request")}
        >
          {t("Request credit")}
          <span className="mx-1">
            <FontAwesomeIcon
              icon={faArrowRightToBracket}
              className="rotate-90"
            />
          </span>
        </RequestButton>
        <RequestButton
          disabled={!user.profile.parent}
          onClick={() => handleRequestAction("return")}
        >
          {t("Return credit")}
          <span className="mx-1">
            <FontAwesomeIcon icon={faArrowTrendUp} />
          </span>
        </RequestButton>
      </div>
      <div className="flex flex-row flex-wrap gap-4 justify-content">
        {!!requests.length &&
          requests?.map((request) => (
            <RequestCard
              request={request}
              key={request._id}
              fetch={invalidateRequestList}
            />
          ))}
      </div>

      <Modal
        visible={requestModal.visible ?? false}
        onClose={() => setRequestModal({ visible: false })}
        title="Select Agent"
        className="flex flex-col w-[25rem] max-w-full mx-auto p-5 justify-center items-center"
      >
        {(!(
          requestModal.type === "request" || requestModal.type === "return"
        ) && (
          <div className="flex w-full flex-col gap-4 pt-3 my-3 max-h-[35vh] min-h-[300px] overflow-y-auto px-4">
            {!!agentsList?.length &&
              agentsList.map((agent) => (
                <button
                  key={agent._id}
                  className={classNames(
                    "hover:bg-primary/10 transition-colors",
                    "flex flex-row text-lg p-3 rounded-lg border gap-4 whitespace-nowrap flex-nowrap",
                    {
                      "ring-2 !bg-primary text-white":
                        agent._id === selectedAgent,
                    }
                  )}
                  onClick={() =>
                    setSelectedAgent((id) => (agent.id === id ? "" : agent._id))
                  }
                >
                  <div className="h-12 w-12 rounded-full overflow-hidden shadow-md">
                    {agent?.image ? (
                      <img
                        src={agent?.image?.Location}
                        alt=""
                        className="max-h-full max-w-full object-cover h-full w-full"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faUserTie}
                        size="xl"
                        className="text-[inherit]"
                      />
                    )}
                  </div>
                  <div className="flex flex-col justify-start items-start gap-3 leading-3">
                    <div className="text-xs">
                      {t("Level")} {agent.level}
                    </div>
                    <div>{getLocalizedWord(agent.name)}</div>
                    <div className="text-sm font-semibold">
                      {t("Credit")}: {agent.credit}
                    </div>
                  </div>
                </button>
              ))}
          </div>
        )) || <h1>Request to {getLocalizedWord(user.profile.parent.name)}</h1>}
        <div className="w-full my-3">
          <input
            type="number"
            onChange={(e) =>
              setRequestModal((obj) => ({ ...obj, credit: e.target.value }))
            }
            className="bg-primary/10 w-full border-2 px-4 py-2 text-xl text-primary font-bold border-black rounded-xl focus:border-primary focus-visible:border-primary focus-within:border-primary active:border-primary focus-within:shadow-lg"
          />
        </div>
        <MainButton onClick={handleConfirmRequest} className="w-full max-w-[250px] mx-auto">
          Send
        </MainButton>
      </Modal>
    </main>
  );
}
