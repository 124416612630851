import api from "../api";
import schemas from "../schemas";

import { API } from "../../constants";

/*--- AUTH ---*/
const { AUTH } = API;

export const authServices = {
  login: async (obj) => (await api.post(AUTH.LOGIN, obj))?.data,

  getRecoveryCode: async (obj) => {
    const { data } = await api.post(AUTH.RECOVERY, obj);

    return data;
  },

  resetPassword: async (obj, token) => {
    try {
      const { data } = await api.put(AUTH.RESET, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (e) {
      throw new Error(e.response.data.error);
    }
  },
};
