import api from "../api";

import { API } from "../../constants";

/*--- BANNERS ---*/
const { BANNERS } = API;

export const bannersServices = {
  listAllBanners: async (params) =>
    (await api.get(BANNERS.LIST, { params })).data,
};
