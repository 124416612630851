import {
  faArrowLeft,
  faArrowRight,
  faArrowRightToBracket,
  faArrowTrendDown,
  faArrowTrendUp,
  faArrowUpFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API } from "app/constants";
import agentServices from "app/services";
import { selectAuth } from "app/store/slices/auth-slice";
import { getLocalizedNumber, getLocalizedWord } from "app/utils/lang";
import toastPopup from "app/utils/toastPopup";
import { MainButton } from "components";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const { REQUESTS } = API;

const RequestCard = ({ request, fetch }) => {
  const user = useSelector(selectAuth);
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const [loading, setLoading] = useState(false);

  const isSentToUser = request?.to?._id === user._id;
  const isChild = user.profile.children.includes(request.from._id);

  const handleAcceptRequest = () => {
    let endpoint = "";

    switch (request.type) {
      case "send":
      case "request":
        endpoint = isChild ? REQUESTS.ACCEPT_TO_SEND : REQUESTS.ACCEPT_SENT;
        break;
      case "withdraw":
      case "return":
        endpoint = isChild
          ? REQUESTS.ACCEPT_TO_WITHDRAW
          : REQUESTS.ACCETP_WITHDRAW;
        break;
      default:
        endpoint = REQUESTS.GET;
    }
    setLoading(true);
    agentServices
      .acceptRequest(endpoint, request._id)
      .then(() => {
        toastPopup.success("success");
        fetch && fetch();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRejectRequest = () => {
    setLoading(true);
    agentServices
      .rejectRequest(request._id)
      .then(() => {
        toastPopup.success("Request Rejected!");
        fetch && fetch();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <article
      key={request._id}
      className="flex flex-col w-96 p-4 shadow-lg rounded-xl border"
    >
      <div className="flex flex-row justify-between ">
        <div className="flex flex-row items-center gap-1">
          <FontAwesomeIcon
            icon={faArrowRight}
            className="bg-green-700 rounded-full p-1 w-3 h-3 text-white"
          />
          <h5 className="text-primary font-semibold">
            {request.to?.name && getLocalizedWord(request.to?.name)}
          </h5>
        </div>
        <div className="capitalize flex flex-row gap-2 flex-nowrap text-primary/70 drop-shadow-md">
          {t(request.type)}
          <FontAwesomeIcon
            icon={
              request.type === "send"
                ? faArrowUpFromBracket
                : request.type === "withdraw"
                ? faArrowTrendDown
                : request.type === "request"
                ? faArrowRightToBracket
                : faArrowTrendUp
            }
          />
        </div>
      </div>
      <div className="flex flex-row justify-between ">
        <div className="flex flex-row items-center gap-1">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="bg-red-700 rounded-full p-1 w-3 h-3 text-white"
          />
          <h6 className="text-primary font-semibold">
            {request.from?.name && getLocalizedWord(request.from?.name)}
          </h6>
        </div>
        <span className="text-sm" dir="ltr">
          {dayjs(request.timestamp).format("DD-MM-YYYY\nhh:ss a")}
        </span>
      </div>
      <div className="flex flex-row justify-between ">
        <div className="flex flex-row">
          <h6 className="font-semibold text-slate-800">
            {t("credit", { count: request.credit })}{" "}
            {(request.credit && getLocalizedNumber(request.credit)) || ""}
          </h6>
        </div>
        <span className="text-sm font-semibold capitalize">
          {t(request.status)}
        </span>
      </div>
      {isSentToUser && (
        <>
          <MainButton
            size="small"
            className="mt-5"
            onClick={handleAcceptRequest}
            loading={loading}
          >
            {t("Approve")}
          </MainButton>
          <MainButton
            size="small"
            variant="danger"
            className="mt-5"
            onClick={handleRejectRequest}
            loading={loading}
          >
            {t("Decline")}
          </MainButton>
        </>
      )}
    </article>
  );
};

export default RequestCard;
