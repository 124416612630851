import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { IconButton, MainButton } from "components/Buttons";
import { MainInput } from "components/Inputs";
import { debounce } from "lodash";
import { useEffect, useState } from "react";

const Search = ({ setSearchQuery, onClick }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    const debouncedOnChange = debounce(setSearchQuery, 500);
    debouncedOnChange(value);

    return () => debouncedOnChange.cancel();
  }, [value]);

  return (
    <header className="flex flex-row shadow h-36 bg-gradient-to-r from-primary/30 via-primary/50 to-primary/30 rounded-b-xl">
      <div className="flex flex-row flex-nowrap justify-center items-center  gap-4 min-w-[200px] w-[70vw] mx-auto max-w-[83%]">
        <MainInput
          name="search"
          className="flex-grow"
          onChange={(e) => setValue(e.target.value)}
          onKeyUp={(e) => e.code === "Enter" && onClick()}
          value={value}
        />
        <MainButton
          className="h-full shadow-lg aspect-square hover:shadow-md"
          onClick={onClick}
        >
          <IconButton icon={faMagnifyingGlass} variant="secondary" />
        </MainButton>
      </div>
    </header>
  );
};

export default Search;
