import toastPopup from "app/utils/toastPopup";
import { EVENTS, socket } from "./config";
import store from "app/store";

export function createRoom(reciever) {
  const userId = store.getState().auth._id;
  const userRole = store.getState().auth.profile?.role;
  socket.emit(EVENTS.CHAT.CREATE, {
    members: { [userRole]: userId, ...reciever },
  });
}

export function getRoom(_id, onEvent) {
  socket.emit(EVENTS.CHAT.GET, { _id });
  socket.once(EVENTS.CHAT.GET, onEvent);
}

export function listRooms(members, onEvent) {
  socket.emit(EVENTS.CHAT.LIST, { members });
  socket.once(EVENTS.CHAT.LIST, onEvent);
}

export function sendMessage(message) {
  if (socket.connected) {
    socket.emit(EVENTS.CHAT.MESSAGE, message);
  } else {
    toastPopup.error("Not Connected!");
  }
}
