import { clientsServices } from "app/services/modules/clients";
import { getLocalizedNumber, getLocalizedWord } from "app/utils/lang";
import toastPopup from "app/utils/toastPopup";
import { MainButton } from "components";
import { MainInput } from "components/Inputs";
import MainImage from "components/MainImage/MainImage";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { json, useLoaderData } from "react-router-dom";

const Client = () => {
  const client: IClientItem = useLoaderData();
  const { t } = useTranslation();

  const [credits, setCredits] = useState({ value: client.credit ?? 0 });

  function onUpdateCredits(evt) {
    evt.preventDefault();
    clientsServices
      .updateClient({
        _id: client._id,
        data: { credit: credits.value },
      })
      .then(() => {
        toastPopup.success(t("success") + "!");
      })
      .catch(() => {});
  }
  return (
    <div className="flex flex-col app-card-shadow p-5 gap-4">
      <header>
        <h3 className="text-primary text-center">
          {getLocalizedWord(client.name)}
        </h3>
      </header>
      <main className="flex flex-col gap-5 mx-auto">
        <div className="flex flex-col gap-3 border border-primary/50 rounded-lg mt-10">
          <div className="h-20 w-20 rounded-full overflow-hidden mx-auto border -mt-10">
            <MainImage src={client?.image?.Location} />
          </div>
          <div className="flex flex-row gap-3 text-xl w-full  p-5 rounded-lg">
            <span dir="ltr">
              {client?.phone ? maskPhoneNumber(client?.phone) : ""}
            </span>
          </div>
          <div className="flex flex-row gap-3 text-xl w-full  p-5 rounded-lg">
            <span>
              {t(
                client?.usedFreeTrial
                  ? "Used free Trial"
                  : "Didn't use free Trial"
              )}
            </span>
          </div>
          <div className="flex flex-row gap-3 text-xl w-full  p-5 rounded-lg">
            <p className="capitalize">{t("Is Subscribed")} : </p>
            <span>
              {t(client?.isSubscribed ? "Subscribed" : "Not Subscribed")}
            </span>
          </div>
          <div className="flex flex-row gap-3 text-xl w-full  p-5 rounded-lg">
            <p className="capitalize">
              {t("credit", { count: client?.credit ?? 0 })} :{" "}
            </p>
            <span>{getLocalizedNumber(client?.credit ?? 0)}</span>
          </div>
        </div>

        <form
          className="flex flex-col gap-3 text-xl w-full border border-primary/50 p-5 rounded-lg"
          onSubmit={onUpdateCredits}
        >
          <MainInput
            state={credits}
            setState={setCredits}
            name="value"
            type="number"
            min="0"
          />
          <MainButton text={"updateCredits"} type="submit" />
        </form>
      </main>
    </div>
  );
};

export default Client;

export async function loader({ params }) {
  const response = await clientsServices.getClient({ _id: params.clientId });
  if (!response.success) {
    return json("Client Not found!", {
      status: 500,
    });
  } else {
    return response.record[0];
  }
}

export type IName = {
  en: null | string;
  ar: null | string;
};
export type IOrg = {
  _id: string;
  name: IName;
};
export type ILocation = {
  lat: null;
  long: null;
};
export type ICountry = {
  en: null;
  ar: null;
};
export type ICity = {
  en: null;
  ar: null;
};

export type IClientItem = {
  _id: string;
  name: IName;
  barcode: string;
  org?: IOrg;
  email: null;
  phone: null;
  image: null;
  age: null;
  gender: null;
  isActive: boolean;
  location: ILocation;
  country: ICountry;
  city: ICity;
  joinDate: string;
  role: string;
  type: string;
  dateOfBirth: null;
  interests: any[];
  profession: any[];
  usedFreeTrial: boolean;
  isSubscribed: boolean;
  credit: number;
  isPending: boolean;
};
function maskPhoneNumber(phoneNumber) {
  if (phoneNumber.length < 10) {
    return phoneNumber;
  }

  var maskedNumber = phoneNumber.replace(/(\d{3})\d+(\d{2})/, "$1******$2");
  return maskedNumber;
}
