import api from "../api";

import { API } from "../../constants";

/*--- PAGES ---*/
const { PAGES } = API;

export const pagesServices = {
  getPage: async (params) => (await api.get(PAGES.GET, { params })).data,
  listAllPages: async (params) => (await api.get(PAGES.LIST, { params })).data,
  listAllSettings: async () => {
    const { data } = await api.get(`setting/get`);
    return data?.records ?? data?.record;
  },
};
