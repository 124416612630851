import store from ".";
import {
  loginThunk,
  logout as logoutAction,
  setUpdateProfile,
} from "./slices/auth-slice";
import { setNotifications as setNotificationsAction } from "./slices/notification-slice";
const { dispatch } = store;

//auth
export const login = async (data) => dispatch(loginThunk(data)).unwrap();
export const logout = () => dispatch(logoutAction());
export const updateProfile = (data) => dispatch(setUpdateProfile(data));

// notification
export const setNotifications = (data) =>
  dispatch(setNotificationsAction(data));
