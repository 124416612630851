import toastPopup from "app/utils/toastPopup";
import { MainButton } from "components";
import { MainInput } from "components/Inputs";
import { useTranslation } from "react-i18next";

function ConfirmCodeView({ input, setInput }) {
  const { t } = useTranslation();
  async function handleCompareCode() {
    if (input.code.toString() === input.recievedCode.toString()) {
      setInput((obj) => ({
        ...obj,
        step: 2,
      }));
    } else {
      toastPopup.error("The code didn't match");
    }
  }
  return (
    <>
      <h3 className="capitalize">{t("forgotPassword")}</h3>
      <p className="w-2/3 my-3 text-center">
        {t("Please enter the sent code")}
      </p>

      <MainInput
        state={input}
        setState={setInput}
        name="code"
        className="w-4/5"
      />

      <MainButton
        text="send"
        loading={false}
        type="submit"
        className="w-4/5 mt-8"
        onClick={handleCompareCode}
      />
    </>
  );
}
export default ConfirmCodeView;
