import { CardContainer } from "components";
import { Outlet } from "react-router-dom";
import { ROUTES } from "../../app/constants";
import RoutingTab from "../../components/RoutingTab/RoutingTab";

const RequestsPage = function () {
  return (
    <CardContainer
      title="requests"
      className="max-w-5xl mx-auto p-2 md:p-4 lg:p-8"
    >
      <RoutingTab
        routes={[
          { name: "incoming", route: ROUTES.REQUESTS_VIEWS.INCOMING },
          { name: "outgoing", route: ROUTES.REQUESTS_VIEWS.OUTGOING },
        ]}
      />
      <Outlet />
    </CardContainer>
  );
};

export default RequestsPage;
