import { API } from "app/constants";
import api from "../api";

/*--- AGENTS ---*/
const { ADS } = API;

export const adsServices = {
  createAd: async (obj) => {
    const { data } = await api.post(ADS.CREATE, obj);
    return data;
  },

  getAd: async (params) => (await api.get(ADS.GET, { params })).data,

  listAllAds: async (params) => (await api.get(ADS.LIST, { params })).data,

  uploadAdImg: async (id, imgFormData) => {
    const { data } = await api.post(ADS.IMG + `?_id=${id}`, imgFormData);
    return data;
  },
};
