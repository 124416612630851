import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

function FormErrorMessage({ errorList }) {
  const { t } = useTranslation();

  if (!errorList?.length) return null;
  return errorList?.map((error, idx) => {
    return (
      <div
        key={error}
        className="mx-auto max-w-[95%] md:max-w-[80%] animate__animated animate__bounceIn duration-75"
        style={{ animationDelay: 0.03 * idx + "s" }}
      >
        <div className="err !text-sm font-serif !font-normal gap-3 flex flex-row whitespace-normal items-center !py-2 !px-3">
          <FontAwesomeIcon icon={faExclamationTriangle} size="xl" />
          <span>{t(error)}</span>
        </div>
      </div>
    );
  });
}
export default FormErrorMessage;
