import store from "app/store";
import toastPopup from "app/utils/toastPopup";
import { API, STORE } from "../../constants";
import api from "../api";
import { setLocalStorageKey } from "app/store/slices/auth-slice";

/*--- AGENTS ---*/
const { AGENTS } = API;
const { AUTH } = STORE;

export const agentsServices = {
  createAgent: async (obj) => {
    const { data } = await api.post(AGENTS.CREATE, obj);
    toastPopup.success("Agent Invited successfully");
    return data;
  },

  getAgent: async (params) => (await api.get(AGENTS.GET, { params })).data,

  listAllAgents: async (params) =>
    (await api.get(AGENTS.LIST, { params: { ...params, isActive: true } }))
      .data,

  updateAgent: async (agentId, obj) => {
    // update my profile
    const { data } = await api.put(AGENTS.UPDATE + `?_id=${agentId}`, obj);
    if (agentId === store.getState()._id)
      setLocalStorageKey(AUTH.PROFILE, data.record);
    return data;
  },

  uploadImage: async (imgFormData) => {
    const { data } = await api.post(
      AGENTS.IMG + `?_id=${store.getState().auth._id}`,
      imgFormData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return data;
  },

  deleteImage: async () => (await api.delete(AGENTS.IMG)).data,
};
