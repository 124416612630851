import schemas from "../schemas";
import api from "../api";

import { API } from "../../constants";

/*--- JOINS ---*/
const { JOINS } = API;

export const joinsServices = {
  createJoin: async (obj) => {
    const { error, value } = schemas.createJoin.validate(obj);
    if (error) {
      throw new Error(error.message);
    }

    const { data } = await api.post(JOINS.CREATE, obj);
    return data;
  },
};
