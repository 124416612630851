import i18n from "app/locales/i18n";
import toastPopup from "app/utils/toastPopup";
import axios from "axios";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import { API } from "../constants";
import store from "../store";
import { logout } from "../store/slices/auth-slice";

const { AUTH } = API;
const guestEndpoints = [...Object.values(AUTH)];

const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
    "x-app-token": "VIP-Team",
    "accept-language": i18n.language,
  },
});

api.interceptors.request.use(async (req) => {
  const endpointUrl = req.url;
  const auth = store.getState().auth;
  const role = auth.profile?.role ?? auth.role;

  if (!role) toastPopup.error("Please check the selected role!");
  req.url = baseURL + `/${role}/` + endpointUrl;

  if (guestEndpoints.includes(endpointUrl)) return req;

  const token = jwt_decode(auth.token);

  const isTokenExpired = dayjs.unix(token.exp).diff(dayjs()) <= 1;

  if (!isTokenExpired) {
    req.headers.Authorization = `Bearer ${auth.token}`;
    return req;
  }

  if (isTokenExpired) {
    localStorage.removeItem("token");
    store.dispatch(logout());

    return Promise.reject("you are unautherised");
  }
});



export default api;
