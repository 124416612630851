import { requestsServices } from "app/services/modules/requests";
import { RequestCard } from "components/Cards";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import NoData from "components/NoData/NoData";
import { useState } from "react";
import useSWR from "swr";

export default function IncomingView() {
  const [requests, setRequests] = useState([]);
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    "list-all-in-reqs",
    requestsServices.listAllIncomingRequests,
    {
      onSuccess: (data) => {
        if (data.records || data.record)
          setRequests(data.records ?? [data.record]);
      },
    }
  );

  if (isLoading)
    return (
      <div className="flex justify-center items-center w-full h-full">
        <LoadingSpinner />
      </div>
    );

  if (!isLoading && !requests.length)
    return (
      <div className="p-8 flex justify-center items-center w-full h-full">
        <NoData />
      </div>
    );

  return (
    <main className="app-card flex flex-row flex-wrap gap-4 justify-content">
      {!!requests.length &&
        requests?.map((request) => (
          <RequestCard request={request} key={request._id} fetch={mutate} />
        ))}
    </main>
  );
}
